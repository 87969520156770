exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuality-js": () => import("./../../../src/pages/aktuality.js" /* webpackChunkName: "component---src-pages-aktuality-js" */),
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-o-me-js": () => import("./../../../src/pages/o-me.js" /* webpackChunkName: "component---src-pages-o-me-js" */),
  "component---src-pages-programy-js": () => import("./../../../src/pages/programy.js" /* webpackChunkName: "component---src-pages-programy-js" */),
  "component---src-pages-webooker-js": () => import("./../../../src/pages/webooker.js" /* webpackChunkName: "component---src-pages-webooker-js" */)
}

